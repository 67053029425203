import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getWatched, getWatching, getVideos } from "../utils/fetchFromAPI";
import { Videos, Loader } from "./";
import { handleError } from "../utils/error";

const History = () => {
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const watchedIds = await getWatched();
        const watchingIds = await getWatching();
        const allIds = [...new Set([...watchingIds, ...watchedIds])];
        const videosData = await getVideos(allIds);
        setVideos(videosData);
      } catch (error) {
        handleError("History: getVideos", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) return <Loader />;
  if (error) return <Typography variant="h6" color="error">Error loading videos</Typography>;

  return (
    <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
      <Typography variant="h4" gutterBottom>History</Typography>
      <Videos videos={videos} />
    </Box>
  );
};

export default History;
