import React, { useState, useEffect } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PIN from './PIN';
import { getSettingsAPI } from '../utils/fetchFromAPI';

const TestPIN = ({ destination }) => {
  const [pin, setPin] = useState('');
  const [storedPin, setStoredPin] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getSettingsAPI().then((settings) => {
      setStoredPin(settings.secrets.pin);
    });
  }, []);

  const handlePinChange = (newPin) => {
    setPin(newPin);
    setError('');

    if (newPin.length >= 4) {
        console.log(storedPin );
      if (newPin === storedPin) {
        navigate(destination);
      } else {
        setError('Incorrect PIN. Please try again.');
        setPin(''); // Clear the PIN entry
      }
    }
  };

  return (
    <Stack spacing={4} alignItems="center" justifyContent="center">
      <Typography variant="h5">Enter PIN</Typography>
      <PIN  onPinChange={handlePinChange} pin={pin} hidePINEntry={true} />
      {error && <Typography color="error">{error}</Typography>}      
    </Stack>
  );
};

export default TestPIN;