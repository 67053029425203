import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Box,
  Button,
  Container,
  Typography,

  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import { getSettingsAPI, saveSettingsAPI, getMetadata } from "../utils/fetchFromAPI";
import PIN from "./PIN";
import { handleError } from "../utils/error";

const FirstSetup = ({ onSetupDone }) => {
  const [languages, setLanguages] = useState([]);
  const [music, setMusic] = useState([]); // Add state for music
  const [step, setStep] = useState(1);
  const [selectedLanguages, setSelectedLanguage] = useState([]); // Initialize as an array
  const [selectedMusic, setSelectedMusic] = useState([]); // Add state for selected music
  const [pin, setPin] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    getMetadata()
      .then((metadata) => {
        setLanguages(metadata.languages);
        setMusic(metadata.music); 
        setSelectedMusic(metadata.music);
        setSelectedLanguage(prev => [...prev, metadata.languages[0]]);  
      })
      .catch((error) => {
        setError(error);
        handleError("FirstSetup: getMetadata", error);
      });
  }, []);

  const handleNext = () => {
    if (step === 1 && selectedLanguages.length > 0) { // Check if at least one language is selected
      
      setStep(2);
    } else if (step === 2 && pin.length === 4) {
      

      getSettingsAPI().then((settings) => {
        settings.languages = selectedLanguages; // Save the array of selected languages
        settings.music = selectedMusic; // Save the array of selected music
        settings.secrets.pin = pin;
        settings.secrets.setupComplete = true;
        saveSettingsAPI(settings).then(() => {
          onSetupDone(true);
        }).catch((error) => {
          handleError("FirstSetup: saveSettingsAPI", error);
        });
      })
      .catch((error) => {
        setError(error);
        handleError("FirstSetup: getSettingsAPI", error);
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '350px'
      }}
    >
      
    <Container>
      {step === 1 && (
        <Box p={2}>
          <Stack spacing={4}>
            <Typography variant="h5">
              Select video languages
            </Typography>
            {error && <Typography color="error">Error fetching languages: {error.message}</Typography>}
            {languages.length === 0 ? <CircularProgress size={100}  /> : (
            <FormGroup sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', overflow: 'scroll', maxHeight: '40vh' }}>
              {languages.map((language) => (
                <FormControlLabel
                  key={language}
                  disabled={language === "English"}
                  control={
                    <Checkbox
                      checked={selectedLanguages.includes(language)}
                      onChange={() => {
                        if (selectedLanguages.includes(language)) {
                          setSelectedLanguage(selectedLanguages.filter(lang => lang !== language));
                        } else {
                          setSelectedLanguage([...selectedLanguages, language]);
                        }
                      }}
                    />
                  }
                  label={language}
                />
                ))}
              </FormGroup>
            )}

            <Typography variant="h5">
              How much music is ok?
            </Typography>
            {music.length === 0 ? <CircularProgress size={100} /> : (
              <FormGroup sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', overflow: 'scroll', maxHeight: '40vh' }}>
                {music.map((genre) => (
                  <FormControlLabel
                    key={genre}
                    control={
                      <Checkbox
                        checked={selectedMusic.includes(genre)}
                        onChange={() => {
                          if (selectedMusic.includes(genre)) {
                            setSelectedMusic(selectedMusic.filter(m => m !== genre));
                          } else {
                            setSelectedMusic([...selectedMusic, genre]);
                          }
                        }}
                      />
                    }
                    label={genre}
                  />
                ))}
              </FormGroup>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={selectedLanguages.length === 0} // Disable if no language is selected
              fullWidth
            >
              Next
            </Button>
          </Stack>
        </Box>
      )}
      {step === 2 && (
        <Box p={2}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">Set up a parental lock PIN</Typography>
            <PIN onPinChange={setPin} pin={pin} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={pin.length !== 4}
              fullWidth
            >
              Next
            </Button>
          </Stack>
        </Box>
      )}
      <Typography variant="body2" color="textSecondary">
        You can change these settings later from the settings screen. There are more options available like Music, Audiences, Genders, Age Groups, etc.
      </Typography>
    </Container>
    </Box>
  );
};

export default FirstSetup;