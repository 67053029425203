import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => {
  return (
    <Link to="/search/" >
      <SearchIcon sx={{ fontSize: '2rem' }} />
      &nbsp;
    </Link>
  );
};

export default SearchBar;
