import React, { useState, useEffect } from "react";
import { Box, TextField, Button, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { searchVideos } from "../utils/fetchFromAPI";
import SearchFeed from "./SearchFeed";
import Loader from "./Loader";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { handleError } from "../utils/error";

const SearchPage = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { searchTerm } = useParams();

  const handleSearch = async (e) => {
    e.preventDefault();

    navigate(`/search/${query.trim()}`);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      setQuery(searchTerm);
      setIsLoading(true);

      console.log("searchTerm:", searchTerm);
      searchVideos(searchTerm)
        .then((results) => {
          setSearchResults(results);
        })
        .catch((error) => {
          setSearchResults(null);
          handleError("SearchPage: searchVideos", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {

    }
  }, [searchTerm]);

  return (
    <Box sx={{ p: 2 }}>
      <Box
        component="form"
        onSubmit={handleSearch}
        sx={{ display: "flex", mb: 2 }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{ mr: 1 }}
          autoFocus={true}
        />
        <Button type="submit" variant="contained">
          <SearchIcon />
        </Button>
      </Box>

      {isLoading ? (
        <Loader />
      ) : searchResults ? (
        <SearchFeed videos={searchResults} searchTerm={query} />
      ) : null}
    </Box>
  );
};

export default SearchPage;
