import InfiniteVideos from "./InfiniteVideos";
import { useState, useCallback, useEffect } from "react";
import { getHome } from "../utils/fetchFromAPI";
import videoUtils from "../utils/videoUtils";
import { getWatched } from "../utils/fetchFromAPI";
import { Button, Stack } from "@mui/material";
import { handleError } from "../utils/error";

const HomeVideos = () => {
  const [videos, setVideos] = useState(
    videoUtils.getCachedVideos("homeVideos")
  );
  const [hasMore, setHasMore] = useState(true);

  const getVideos = useCallback(
    (page) => {
      if (!hasMore) return;
      getHome(page, []).then((data) => {
        setVideos((prevVideos) =>
          prevVideos ? [...prevVideos, ...data] : data
        );
        videoUtils.cacheVideos("homeVideos", videos);

        setHasMore(data.length >= 0);
      }).catch((error) => {
        handleError("HomeVideos: getVideos", error);
      });
    },
    [videos]
  );

  useEffect(() => {
    if (!videos) {
      getVideos(1);
    }
  }, [getVideos, videos]);

  const onLoadMoreVideos = getVideos;

  return (
      <InfiniteVideos
        videos={videos}
        hasMore={hasMore}
        onLoadMoreVideos={onLoadMoreVideos}
      />
  );
};

export default HomeVideos;
