import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { getSettingsAPI, getMetadata, saveSettingsAPI, clearSettings } from "./utils/fetchFromAPI";
import { FirstSetup } from "./components";
import ReactPlayer from "react-player";

import {
  VideoDetail,
  SearchPage,
  Navbar,
  Feed2,
  History,
  Footer,
  Settings,
  TestPIN,
} from "./components";
import Loader from "./components/Loader";
import { handleError } from "./utils/error";
import videoUtils from "./utils/videoUtils";

const App = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [isSetupDone, setIsSetupDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    getMetadata().then((metadata) => {

      getSettingsAPI().then((data) => {
        setIsLoading(false);

        const invalidAgeGroups = data.ageGroups.filter(age => !metadata.ageGroups.includes(age));
        const invalidLanguages = data.languages.filter(language => !metadata.languages.includes(language));
        const invalidAudiences = data.audiences.filter(audience => !metadata.audiences.includes(audience));
        const invalidMusic = data.music.filter(music => !metadata.music.includes(music));
        const invalidGenders = data.genders.filter(gender => !metadata.genders.includes(gender));

        if (invalidAgeGroups.length > 0 
          || invalidLanguages.length > 0 
          || invalidAudiences.length > 0 
          || invalidMusic.length > 0 
          || invalidGenders.length > 0) {

          clearSettings();
          setIsSetupDone(false);
          videoUtils.clearVideoCaches();

        } else {
          
          setIsSetupDone(data.secrets?.setupComplete === true ? true : false);
        }
        
      }).catch((error) => {
        handleError("App: getSettingsAPI", error);
        
      });
  
      
    }).catch((error) => {
      handleError("App: getMetadata", error);
    });



    videoUtils.clearVideoCaches();
    var timer = setInterval(() => {
      videoUtils.clearVideoCaches();
    }, 10*60*1000);
    return () => clearInterval(timer);

    
    
  }, []);


  return (
    <BrowserRouter>
      <Box>
        {!fullScreen && <Navbar />}
        <Routes>
          <Route path="/" element={!isSetupDone ? isLoading ? <Loader /> : <FirstSetup onSetupDone={setIsSetupDone} /> : <Feed2 />} />
          <Route path="/category/:category" element={<Feed2 />} />
          <Route path="/video/:id" element={<VideoDetail onFullScreen={setFullScreen} />} />
          <Route path="/search/:searchTerm?" element={<SearchPage />} />
          <Route path="/history" element={<History />} />
          <Route path="/settings" element={<TestPIN destination='/settings_pin_passed' />} />
          <Route path="/settings_pin_passed" element={<Settings />} />
        </Routes>
        {!fullScreen && isSetupDone && <Footer />}
      </Box>
      {/* A Hidden ReactPlayer to precache player assets  */}
      <ReactPlayer
        style={{ visibility: "hidden", position: "fixed", top: "-1000px", left: "-1000px" }}
        width={"10"}
        height={"100"}
        url="https://youtu.be/6WcrWySyH9g"
        muted={true}
        playing={true}
        controls={false}
      />
    </BrowserRouter>
  );
};

export default App;
