import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";
import {
  PlayArrow,
  Pause,
  VolumeOff,
  VolumeUp,
  Fullscreen,
} from "@mui/icons-material";
import "../css/video-player.css";
import screenfull from "screenfull";
import { ScreenOrientation } from "@capacitor/screen-orientation";

const VideoPlayer = ({
  url,
  onVideoStart,
  onVideoProgress,
  onFullScreen,
  playing: initialPlaying,
}) => {
  const [playerState, setPlayerState] = useState({
    playing: initialPlaying || false,
    muted: false,
    volume: 1,
    playerbackRate: 1.0,
    played: 1,
    seeking: false,
  });
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const playerRef = useRef(null);
  const playerDivRef = useRef(null);
  const [showControls, setShowControls] = useState(false);
  const controlsTimeoutRef = useRef(null);

  const togglePlay = () =>
    setPlayerState((prev) => ({ ...prev, playing: !prev.playing }));
  const toggleMute = () =>
    setPlayerState((prev) => ({ ...prev, muted: !prev.muted }));

  const handleFullScreenMode = () => {
    setIsFullScreen((prev) => !prev);
  };

  const handleProgress = (state) => {
    setPlayerState((prev) => ({ ...prev, played: state.played }));
    onVideoProgress(state);
  };

  const handleVideoStart = () => {
    setPlayerState((prev) => ({ ...prev, playing: true }));
    onVideoStart();
  };

  const handleVideoEnd = () => {
    setPlayerState((prev) => ({ ...prev, playing: false, played: 0 }));
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleTouchStart = () => {
    displayControls();
  };

  function handleOrientationChange(event) {
    console.log(event);
    if (event.type.indexOf("landscape") !== -1) {
        console.log("landscape");
        setIsFullScreen(true);
    } else {
        console.log("portrait");
        setIsFullScreen(false);
    }
  }

  useEffect(() => {
    onFullScreen(isFullScreen);
  }, [onFullScreen, isFullScreen]);

  const handleSeek = (e) => {
    const seconds = parseFloat(e.target.value);
    setPlayerState((prev) => ({
      ...prev,
      played: parseFloat(e.target.value),
      seeking: true,
    }));
    playerRef.current.seekTo(seconds);
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const currentTime = formatTime(duration * playerState.played);
  const totalTime = formatTime(duration);

  const clearControlsHideTimeout = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
  };

  const hideControlsAfterDelay = () => {
    clearControlsHideTimeout();
    controlsTimeoutRef.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  const displayControls = () => {
    setShowControls(true);
    hideControlsAfterDelay();
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleTouchStart);

    ScreenOrientation.addListener(
      "screenOrientationChange",
      handleOrientationChange
    );

    return () => {
      cleanup();
    };
  }, []);  

  function cleanup() {
    document.removeEventListener("touchstart", handleTouchStart);
    clearControlsHideTimeout();

    ScreenOrientation.removeListener(
      "screenOrientationChange",
      handleOrientationChange
    );
  }

  return (
    <Box
      className={`video-player ${isFullScreen ? "fullscreen" : ""}`}
      onClick={displayControls}
      onDoubleClick={displayControls}
      ref={playerDivRef}
    >
      <div
        className="video-player-overlay"
        // style={{ display: showControls ? "none" : "block" }}
        onClick={displayControls}
        onMouseEnter={displayControls}
        onMouseLeave={hideControlsAfterDelay}
      ></div>
      <ReactPlayer
        ref={playerRef}
        url={url}
        width={"100%"}
        height={"100%"}
        volume={playerState.volume}
        playing={playerState.playing}
        muted={playerState.muted}
        playbackRate={playerState.playerbackRate}
        onClick={displayControls}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onStart={handleVideoStart}
        onPause={displayControls}
        onPlay={hideControlsAfterDelay}
        onEnded={handleVideoEnd}        
        controls={false}
        config={{
          youtube: {
            playerVars: { autoplay: 1, rel: 0, modestbranding: 1 },
          },
        }}
      />
      <div
        className={`controls ${showControls ? "visible" : ""}`}
        onBlur={hideControlsAfterDelay}
      >
        <button className="control-button big-button" onClick={togglePlay}>
          {playerState.playing ? <Pause fontSize="large" /> : <PlayArrow fontSize="large" />}
        </button>
        <input
          type="range"
          min={0}
          max={1}
          step="any"
          value={playerState.played}
          onChange={handleSeek}
          onMouseUp={() =>
            setPlayerState((prev) => ({
              ...prev,
              playing: true,
              seeking: false,
            }))
          }
          className="progress-slider"
        />
        <button className="control-button" onClick={toggleMute}>
          {playerState.muted ? <VolumeOff /> : <VolumeUp />}
        </button>
        <button className="control-button" onClick={handleFullScreenMode}>
          <Fullscreen />
        </button>
        <div className="time-display">
          {currentTime} / {totalTime}&nbsp;
        </div>
      </div>
    </Box>
  );
};

export default VideoPlayer;
