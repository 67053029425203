import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom"; 
import { Stack, Card, CardContent, CardMedia } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { formatDistanceToNow } from 'date-fns';

const VideoCard = ({ video, isWatched, isWatching } ) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);
  const watchedIcon = isWatched ? 
  
  <CheckCircleIcon className='watched-icon' sx={{ color: 'brown' }} fontSize="large" /> 
    : isWatching ? <TimelapseIcon className='watched-icon' sx={{ color: 'darkred' }} fontSize="large" /> 
    : null;
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    let currentCardRef = cardRef.current;
    if (currentCardRef) {
      observer.observe(currentCardRef);
    }

    return () => {
      if (currentCardRef) {
        observer.unobserve(currentCardRef);
      }
    };
  }, []);

  if (!video || !video.thumbnail) {
    return null; 
  }

  const {
    video_id = '',
    title = 'No title available',
    channel_title = 'Unknown channel',
    published_at = new Date().toISOString(),
    thumbnail,
    duration = ''
  } = video;

  return (
    <Card ref={cardRef} elevation={0} className="video-card" 
    sx={{ width: { sm: '350px', md: "350px", lg: "400px" }, backgroundColor: "transparent" }}>
      {false && (
        <div className='duration'>
          {duration.replace(/PT(\d+H)?(\d+M)?(\d+S)?/, (match, hours, minutes, seconds) => {
            const h = hours ? parseInt(hours) : 0;
            const m = minutes ? parseInt(minutes) : 0;
            const s = seconds ? parseInt(seconds) : 0;
            const totalMinutes = h * 60 + m + Math.ceil(s / 60);
            return `${Math.floor(totalMinutes / 60).toString().padStart(2, '0')}:${(totalMinutes % 60).toString().padStart(2, '0')}`;
          })}
        </div>
      )}
      <Link to={`/video/${video_id}`}>
        {isVisible && (
          <CardMedia             
            className='video-card-image' 
            image={thumbnail} 
            alt={title} 
            sx={{ width: '330px', height: { xs: "180px" }, margin: '0 auto' }} 
          />
        )}        
      </Link>
      {watchedIcon}
      
      <CardContent className='video-card-content' >
        <Stack direction="column">
          <Link className='video-card-title' to={`/video/${video_id}`} >
              {title}
          </Link>
          <span className='video-card-channel'>
            {channel_title}
          </span>
          <span className='video-card-published-at'>
            {formatDistanceToNow(new Date(published_at), { addSuffix: true }).replace('about', '').replace('almost', '')}
          </span>

        </Stack>
      </CardContent>
    </Card>
  );

};

export default VideoCard;