import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography, Box, Stack, Button } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { Videos, Loader } from "./";
import {
  getVideo,
  getRelatedVideos,
  addToWatched,
  addToWatching,
} from "../utils/fetchFromAPI";
import VideoPlayer from "./VideoPlayer";
import videoUtils from "../utils/videoUtils";
import { handleError } from "../utils/error";

const handleVideoProgress = (id, progress) => {
  if (progress.played > 0.8) {
    addToWatched(id);
  }
};

const handleVideoStart = (id) => {
  addToWatching(id);
};

const VideoDetail = ({ onFullScreen }) => {
  const [playing, setPlaying] = useState(false);
  const [videoDetail, setVideoDetail] = useState(null);
  const [videos, setVideos] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = (isFullScreen) => {
    setIsFullScreen(isFullScreen);
    onFullScreen(isFullScreen);
  };

  useEffect(() => {
    setVideos(null);
    setError(null);
    window.scrollTo(0, 0);

    videoUtils.rememberLastVideo(id);
    
    getVideo(id)
      .then((data) => setVideoDetail(data))
      .catch((err) => {
        handleError("VideoDetail: getVideo", err);
        setError("Failed to load video details");
      });

    var timer = setTimeout(() => {
    getRelatedVideos(id)
      .then((data) => setVideos(data))
      .catch((err) => {
        handleError("VideoDetail: getRelatedVideos", err);
        setError("Failed to load related videos");
      });
    }, 5000);

    return () => clearTimeout(timer);
  }, [id]);

  if (error) {
    return (
      <Box
        minHeight="95vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  if (!videoDetail) return <Loader />;

  const {
    title,
    channel_id,
    channel_title,
    video_url,
    summary,
    published_at,
    thumbnail,
  } = videoDetail;

  return (
    <Box minHeight="95vh">
      <Stack direction="column">
        <Box flex={1} flexGrow={1}>
          
            <VideoPlayer 
              url={video_url}
              onVideoStart={() => handleVideoStart(id)}
              onVideoProgress={(progress) => handleVideoProgress(id, progress)}
              onFullScreen={handleFullScreen}
              playing={playing}
            />
            {/* {!isFullScreen && (
              <Stack
                direction="column"
                justifyContent="space-between"
                py={1}
                px={2}
              >
                <div className="video-detail-title">{title}</div>
                <div className="video-detail-channel-title">
                  {channel_title}
                </div>
                <div className="video-detail-publish-date">
                  {formatDistanceToNow(new Date(published_at), {
                    addSuffix: true,
                  })}
                </div>
                <Stack direction="row" gap="5" alignItems="center">
                  <div className="video-detail-description">{summary}</div>
                </Stack>
              </Stack> 
              )}
              */}
            
          
        </Box>
        {!isFullScreen && (
          <Box
            mt={6}
            px={2}
            justifyContent="center"
            alignItems="center"
            >
              <Typography variant="h6">Related Videos</Typography>
            <Videos videos={videos} direction="column" />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default VideoDetail;
