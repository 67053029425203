import { useState, useEffect, useRef } from "react";
import Videos from "./Videos";
import videoUtils from "../utils/videoUtils";
import { Stack, Button } from "@mui/material";

const InfiniteVideos = ({ videos, onLoadMoreVideos }) => {
  const [page, setPage] = useState(videos == null ? 1: Math.floor(videos?.length/20));

  const lastVideoId = videoUtils.getLastVideo();
  console.log("lastVideoId", lastVideoId);

  const onLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
    console.log("Load more videos, page:", page);
    onLoadMoreVideos(page+1);
  };

  return (
    <Stack>
      <Videos
        videos={videos}
        onLoadMore={onLoadMore}
        showVideoId={lastVideoId}
      />
      <Button variant="contained" color="primary" onClick={onLoadMore}>
        Load more
      </Button>
    </Stack>
  );
};

export default InfiniteVideos;
