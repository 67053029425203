import React, { useState, useEffect } from 'react';
import { Link, Typography, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, TextField, Button, Box } from '@mui/material';
import { getSettingsAPI, saveSettingsAPI, getMetadata } from '../utils/fetchFromAPI';
import Loader from './Loader';
import videoUtils from '../utils/videoUtils';
import { handleError } from '../utils/error';

const Settings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const [recommendation, setRecommendation] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    Promise.all([getSettingsAPI(), getMetadata()]).then(([storedSettings, metadata]) => {
      setSettings(storedSettings);
      setMetadata(metadata);
      setIsLoading(false);
    }).catch((error) => {
      handleError("Settings: getSettings, getMetadata", error);
    });
    
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveSettingsAPI(settings).catch((error) => {
        handleError("Settings: saveSettingsAPI", error);
      });
      videoUtils.clearVideoCaches();
    }
  }, [isLoading, settings]);

  const handleCheckboxChange = (setting, value) => {
    setSettings(prev => ({
      ...prev,
      [setting]: prev[setting].includes(value)
        ? prev[setting].filter(item => item !== value)
        : [...prev[setting], value]
    }));
  };

  const handleRadioChange = (setting, value) => {
    setSettings(prev => ({ ...prev, [setting]: value }));
  };

  

  return (
    isLoading ? <Loader /> : 
    <Box sx={{ padding: 2 }}>
      
      <Typography variant="h4" gutterBottom>Settings</Typography>
      
      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Suitable for Age</Typography>
        <FormGroup>
          {metadata.ageGroups.map(group => (
            <FormControlLabel
              key={group}
              control={
                <Checkbox
                  checked={settings.ageGroups.length === 0 || settings.ageGroups.includes(group)}
                  onChange={() => handleCheckboxChange('ageGroups', group)}
                />
              }
              label={group}
            />
          ))}
        </FormGroup>
      </Box>

      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Music</Typography>
        <FormGroup>
          {metadata.music.map(group => (
            <FormControlLabel
              key={group}
              control={
                <Checkbox
                  checked={settings.music.length == 0 || settings.music.includes(group)}
                  onChange={() => handleCheckboxChange('music', group)}
                />
              }
              label={group}
            />
          ))}
        </FormGroup>
      </Box>

      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Video Language</Typography>
        <FormGroup>
          {metadata.languages.map(lang => (
            <FormControlLabel
              key={lang}
              control={
                <Checkbox
                  checked={settings.languages.length == 0 || settings.languages.includes(lang)}
                  onChange={() => handleCheckboxChange('languages', lang)}
                />
              }
              label={lang}
            />
          ))}
        </FormGroup>
      </Box>
      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Audiences</Typography>
        <FormGroup>
          {metadata.audiences.map(option => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={settings.audiences.length == 0 || settings.audiences.includes(option)}
                  onChange={() => handleCheckboxChange('audiences', option)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </Box>

      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Gender</Typography>
        <FormGroup>
          {metadata.genders.map(option => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={settings.genders.length == 0 || settings.genders.includes(option)}
                  onChange={() => handleCheckboxChange('genders', option)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </Box>

      <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 1, p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Recommend YouTube Video or Playlist</Typography>
        <Link href={metadata.recommendFormUrl} target="_blank">
          <Button variant="contained" color="primary">
            Recommend
          </Button>
        </Link>
      </Box>
      
    </Box>
  )};

export default Settings;

