import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from '../theme';

const Footer = () => {
    return (
      <>
        <Box className="footer-gap" />
        <Box className="footer" sx={{ position: 'fixed', bottom: 0, width: '100%', p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: '5px 0' }}>
            <Link to="/" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
              <Box sx={{ textAlign: 'center' }}>
                <Box component="span" className="material-icons" aria-label="videos" color="darkred">movie</Box>
                <Box>Videos</Box>
              </Box>
            </Link>
            <Link to="/history" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
              <Box sx={{ textAlign: 'center' }}>
                <Box component="span" className="material-icons" aria-label="history" color="blue">history</Box>
                <Box>History</Box>
              </Box>
            </Link>
            <Link to="/settings" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
              <Box sx={{ textAlign: 'center' }}>
                <Box component="span" className="material-icons" aria-label="settings" color="green">settings</Box>
                <Box>Settings</Box>
              </Box>
            </Link>
          </Box>
        </Box>
      </>
    );
};

export default Footer;