import React, { useEffect, useState } from "react";
import { Stack, Box } from "@mui/material";
import { getWatched, getWatching } from "../utils/fetchFromAPI";
import { Loader, VideoCard } from "./";
import { Typography } from "@mui/material";
import { useRef, useCallback } from "react";

const Videos = ({ videos, direction, onLoadMore, showVideoId }) => {
  const [watching, setWatching] = useState([]);
  const [watched, setWatched] = useState([]);
  const [complete, setComplete] = useState(false);
  const observer = useRef();
  

  const lastVideoElementRef = useCallback(
    (node) => {
      return;
      let current = observer.current;
      if (current) current.disconnect();
      current = new IntersectionObserver((entries) => {
        current.disconnect();
        if (entries[0].isIntersecting) {
          console.log("loading more");
          onLoadMore();
        }
      });
      if (node) current.observe(node);
    },
    [onLoadMore]
  );

  

  const videoElementRefToScroll = useCallback((node) => {
    if (node) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          node.scrollIntoView();
          //node.scrollBy(0, -100);
        }, 500);
      });
    }
  }, []);

  useEffect(() => {
    Promise.all([getWatching(), getWatched()]).then(([watching, watched]) => {
      setWatching(watching);
      setWatched(watched);
      setComplete(true);
    });
  }, []);

  if (!videos || !complete) return <Loader />;

  if (videos.length === 0) {
    return <Typography>No videos found</Typography>;
  } else {
    return (
      <Stack
        sx={{ flexDirection: { xs: "column", sm: "row" } }}
        flexWrap="wrap"
        alignItems="start"
        justifyContent="start"
        gap={2}
      >
        {videos.map((item, idx) => {
          const isWatching = watching.includes(item.video_id);
          const isWatched = watched.includes(item.video_id);

          if (idx === videos.length - 1) {
            return (
              <Box ref={lastVideoElementRef} key={idx}>
                <VideoCard
                  video={item}
                  isWatching={isWatching}
                  isWatched={isWatched}
                />
              </Box>
            );
          } else {
            return (
              <Box key={idx} ref={item.video_id === showVideoId ? videoElementRefToScroll : null}>
                <VideoCard
                  video={item}
                  isWatching={isWatching}
                  isWatched={isWatched}
                />
              </Box>
            );
          }
        })}
      </Stack>
    );
  }
};

export default Videos;
