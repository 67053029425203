import { Box, Stack } from "@mui/material";
import Categories from "./Categories";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HomeVideos from "./HomeVideos";
import CategoryVideos from "./CategoryVideos";
const Feed2 = () => {
  const HOME = "home";
  const { category } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(category || HOME);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedCategory(category || HOME);
  }, [category]);

  const onSelectCategory = (category) => {
    if (category === HOME) {
      navigate("/");
    } else {
      navigate(`/category/${category}`);
    }
  };

  return (
    <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
      <Box sx={{ height: { sx: "auto", md: "92vh" }, px: { sx: 0, md: 2 } }}>
        <Categories
          home={HOME}
          selectedCategory={selectedCategory}
          onSelectCategory={onSelectCategory}
        />
      </Box>

      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        {selectedCategory === HOME ? <HomeVideos /> : <CategoryVideos category={selectedCategory} />}
      </Box>

      
    </Stack>
  );
};

export default Feed2;
