const rememberLastVideo = (videoId) => {
  window.sessionStorage.setItem("lastVideoId", videoId);
};

const getLastVideo = () => {
  return window.sessionStorage.getItem("lastVideoId");
};

const forgetLastVideo = () => {
  window.sessionStorage.removeItem("lastVideoId");
};

let keys = [];
const cacheVideos = (key, videos) => {
  window.sessionStorage.setItem(key, JSON.stringify(videos));
  keys.push(key);
};

const getCachedVideos = (key) => {
  return JSON.parse(window.sessionStorage.getItem(key));
};

const clearVideoCaches = () => {
  keys.forEach((key) => {
    window.sessionStorage.removeItem(key);
  });
  forgetLastVideo();
  keys = [];
};

const videoUtils = { rememberLastVideo, getLastVideo, forgetLastVideo, cacheVideos, getCachedVideos, clearVideoCaches };

export default videoUtils;