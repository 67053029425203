import { Typography, Box } from "@mui/material";

import { Videos } from "./";

const SearchFeed = ({searchTerm, videos}) => {
  
  return (
    <Box p={2} minHeight="95vh">
      <Typography variant="h5" fontWeight={900} mb={3} ml={{ sm: "100px"}}>
        Search Results for <span style={{ color: "#FC1503" }}>{searchTerm}</span> videos
      </Typography>
      <Box display="flex">
        <Box sx={{ mr: { sm: '100px' } }}/>
        {<Videos videos={videos} />}
      </Box>
    </Box>
  );
};

export default SearchFeed;
