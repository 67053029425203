import { Stack, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { logo } from "../utils/constants";
import { SearchBar } from "./";
import { setAgeGroup, getAgeGroup } from "../utils/fetchFromAPI";
import { useEffect } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Grow } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
const Navbar = ({ age }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const showBackButton = () => location.pathname.includes("/video"); // || location.pathname.includes("/search");
  const backButtonAction = () => {
    
    if (location.pathname.includes("/search")) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };
  return (
    <Stack className="navbar" direction="row" alignItems="center">
      
      <Link to="/" style={{ display: "flex", alignItems: "center" }}>
        <img
          src={require("../assets/KahfKidsLogo.png")}
          alt="logo"
          height={70}
        />
      </Link>
      
      <Link 
      to="#" 
      onClick={backButtonAction}
      style={{
        display: showBackButton() ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
      }}
      >
        <ArrowCircleLeftIcon
          sx={{
            color: "darkgreen",
            fontSize: 60,
          }}
        />
      </Link>
      
      <Box flexGrow={1} alignItems="center" textAlign="center">
        <span className="title">Kahf&nbsp;Kids</span>
      </Box>
      <SearchBar />
    </Stack>
  );
};

export default Navbar;
