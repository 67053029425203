import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getMetadata } from "../utils/fetchFromAPI";
import Sidebar from "./Sidebar";
import { handleError } from "../utils/error";

const Categories = ({ home, selectedCategory, onSelectCategory }) => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    let ignore = false;
    getMetadata().then((metadata) => {
      if (ignore) return;
      setCategories(metadata.categories);
    }).catch((error) => {
      handleError("Categories: getMetadata", error);
    });

    return () => {
      ignore = true;
    };
  }, []);
  return <Sidebar categories={categories} home={home} selectedCategory={selectedCategory} onSelectCategory={onSelectCategory} />;
};

export default Categories;
