import React from "react";
import { Stack } from "@mui/material";

const Sidebar = ({ selectedCategory, onSelectCategory, home, categories }) => {
  return (
    <Stack
      direction="row"
      sx={{
        overflowY: "auto",
        padding: 0,
        height: { sx: "auto", md: "95%" },
        flexDirection: { xs: "row", md: "column" },
      }}
    >
      <button
        className={`category-btn  ${
          selectedCategory === home ? "category-btn-selected" : ""
        }`}
        onClick={() => onSelectCategory(home)}
        key="home"
      >
        Home
      </button>
      {categories.map((category) => (
        <button
          className={`category-btn ${
            category === selectedCategory ? "category-btn-selected" : ""
          }`}
          onClick={() => onSelectCategory(category)}
          key={category}
        >
          <span
            style={{ opacity: category === selectedCategory ? "1" : "0.8" }}
          >
            {category}
          </span>
        </button>
      ))}
    </Stack>
  );
};
export default Sidebar;
