import axios from 'axios';
import { Preferences } from '@capacitor/preferences';

const HISTORY_LENGTH = 100;

const apiClient = axios.create({
    // baseURL: 'http://192.168.1.210:9000', 
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:9000/api' : 'https://kahfkids.com/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

export const getMetadata = async () => {
    try {
        const response = await apiClient.get('/getMetadata', {
            params: {
                random: Math.random()*100000
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching metadata:', error);
        throw error;
    }
};


export const getVideosInCategory = async (category, page) => {
    try {
        var history = await getWatched();
        const settings = await getSettingsForRequest();
        const response = await apiClient.get('/getVideosInCategory', {
            params: { category, page, settings },
            headers: {
                'history': history,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching videos in category:', error);
        throw error;
    }
};

export const getHome = async (page, seen) => {
    try {
        var history = await getWatched();
        const settings = await getSettingsForRequest();
        const response = await apiClient.get('/home', {
            params: { 
                page, 
                settings,
                history,
                seen,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching videos in category:', error);
        throw error;
    }
};

export const getVideo = async (videoId) => {
    try {
        const response = await apiClient.get(`/getVideo/${videoId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching video:', error);
        throw error;
    }
};

export const getRelatedVideos = async (videoId) => {
    try {
        const settings = await getSettingsForRequest();
        const response = await apiClient.get('/getRelatedVideos', {
            params: {
                video_id: videoId,
                settings: settings
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching related videos:', error);
        throw error;
    }
};


export const searchVideos = async (query) => {
    try {
        const settings = await getSettingsForRequest();
        const response = await apiClient.get('/search', {
            params: { query, settings },
        });
        return response.data;
    } catch (error) {
        console.error('Error searching videos:', error);
        throw error;
    }
};


export const addToWatched = async (id) => {
    var history = await getWatched();
    if (!history.includes(id)) {
        if (history.length >= HISTORY_LENGTH) {
            history.shift();
        }
        history.push(id);
        await Preferences.set({ key: 'videoHistory', value: JSON.stringify(history) });
    }
};

export const addToWatching = async (id) => {
    var watching = await getWatching();
    if (!watching.includes(id)) {
        if (watching.length >= HISTORY_LENGTH) {
            watching.shift();
        }
        watching.push(id);
        await Preferences.set({ key: 'videoWatching', value: JSON.stringify(watching) });
    }
};

export const getVideos = async (videoIds) => {
    const response = await apiClient.post('/getVideos', {
        videoIds: videoIds,
    });
    return response.data;
};

export const getWatched = async () => {
    const preferences = await Preferences.get({ key: 'videoHistory' });
    return preferences.value ? JSON.parse(preferences.value) : [];
};

export const getWatching = async () => {
    const preferences = await Preferences.get({ key: 'videoWatching' });
    return preferences.value ? JSON.parse(preferences.value) : [];
};

const defaultSettings = {
    
    "ageGroups": [],
    "genders": [],
    "audiences": [],
    "music": [],
    "languages": [],
    "categories": [],
     
    secrets: {
        pin: '',
        setupComplete: false
    }
};

const getSettingsForRequest = async () => {
    const settings = await getSettingsAPI();
    return { ...settings, secrets: undefined };
};

export const getSettingsAPI = async () => {
    const preferences = await Preferences.get({ key: 'userSettings' });
    const value = typeof preferences?.value === 'string' ? JSON.parse(preferences.value) : defaultSettings;
    if (value == null || typeof value !== 'object') 
        return defaultSettings;
    else { 
        return value;
    }
};

export const saveSettingsAPI = async (settings) => {
    try {
        const existingSettings = await getSettingsAPI();
        const updatedSettings = { ...existingSettings, ...settings };
        await Preferences.set({ key: 'userSettings', value: JSON.stringify(updatedSettings) });
    } catch (error) {
        console.error('Error saving settings:', error);
        throw error;
    }
};

export const clearSettings = async () => {
    await Preferences.remove({ key: 'userSettings' });
};

/* process.env.REACT_APP_RAPID_API_KEY */