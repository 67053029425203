import React from "react";
import { Grid, Button, Typography } from "@mui/material";

const PIN = ({ onPinChange, pin, hidePINEntry = false }) => {
  
  const handleButtonClick = (num) => {
    if (num === 10) {
      pin = pin.slice(0, -1); // Backspace
    } else if (num === 11) {
      pin = "";
    } else {
      pin = pin + num;
    }
    console.log(pin);
    onPinChange(pin);
  };

  return (
    <Grid container columns={{ xs: 3, sm: 3, md: 3 }}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 11].map((num) => (
        <Grid item xs={1} sm={1} md={1} key={num} justifyContent="center" display="flex">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick(num)}
            sx={{
              width: '50px',
              height: '60px',
              margin: '10px',
              borderRadius: '50%',
            }}
          >
            <span style={{ fontSize: '30px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{num === 10 ? "⌫" : num === 11 ? "C" : num}</span>
          </Button>
        </Grid>
      ))}
      <Grid item xs={12} justifyContent="center" display="flex">
        <Typography variant="h6">
          PIN: {hidePINEntry ? '*'.repeat(pin.length) : pin}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PIN;
