import { useState, useCallback, useEffect } from "react";
import InfiniteVideos from "./InfiniteVideos";
import videoUtils from "../utils/videoUtils";
import { getVideosInCategory } from "../utils/fetchFromAPI";
import { handleError } from "../utils/error";

const CategoryVideos = ({ category }) => {
  const [videos, setVideos] = useState(
    videoUtils.getCachedVideos("categoryVideos")
  );
  const [hasMore, setHasMore] = useState(true);

  const getVideos = useCallback(
    (page) =>
      getVideosInCategory(category, page)
        .then((data) => {
          setVideos((prevVideos) =>
            prevVideos ? [...prevVideos, ...data] : data
          );
          videoUtils.cacheVideos("categoryVideos", videos);

          setHasMore(data.length > 0);
        })
        .catch((error) => {
          handleError("CategoryVideos: getVideos", error);
        }),
    [category, videos]
  );

  useEffect(() => {
    if (!videos) {
      getVideos(1);
    }
  }, [getVideos, videos]);

  useEffect(() => {
    console.log("category changed", category);
    setVideos(null);
  }, [category]);

  const onLoadMoreVideos = getVideos;

  return (
    <InfiniteVideos
      videos={videos}
      hasMore={hasMore}
      onLoadMoreVideos={onLoadMoreVideos}
    />
  );
};

export default CategoryVideos;
